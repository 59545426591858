<template>
  <div>
    <div class="row justify-content-start">
      <div class="col-xl-10">
        <div class="timeline timeline-all-left" dir="ltr">
          <!--          <div class="timeline-item timeline-left">-->
          <!--            <div class="timeline-block">-->
          <!--              <div class="time-show-btn mt-0">-->
          <!--                <a href="#" class="btn btn-danger btn-rounded w-lg">2020</a>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <div
              class="timeline-item"
              v-for="(data, index) in timelineData"
              :key="index"
              :class="{'timeline-left': `${data.align}` === 'left'}"
          >
            <div class="timeline-block">
              <div class="timeline-box card">
                <div class="card-body">
                  <span class="timeline-icon"></span>
                  <b-row align-h="between">
                    <b-col class="text-left">
                      <div class="timeline-date">
                        <i class="mdi mdi-circle-medium circle-dot"></i>
                        {{data.date}}
                      </div>
                    </b-col>
                    <b-col class="text-right">
                      <a v-if="data.link" class="btn btn-sm btn-success" :href="data.headerLink.url">{{data.headerLink.text}}</a>
                    </b-col>
                  </b-row>

                  <h5 class="mt-3 font-size-15">{{data.title}}</h5>
                  <div class="text-muted">
                    <p class="mb-0">{{data.text}}</p>
                  </div>
                  <b-row align-h="between">
                    <b-col class="text-left"><a v-if="data.link" :href="data.link.url">{{data.link.text}}</a></b-col>
                    <!--                    <b-col class="text-right"><a v-if="data.link" :href="data.link.url">{{data.link.text}}</a></b-col>-->
                  </b-row>


                  <!--                  <div class="timeline-album" v-if="data.images">-->
                  <!--                    <a href="#" class="mr-1">-->
                  <!--                      <img :src="`${data.images[0]}`" alt />-->
                  <!--                    </a>-->
                  <!--                    <a href="#" class="mr-1">-->
                  <!--                      <img :src="`${data.images[1]}`" alt />-->
                  <!--                    </a>-->
                  <!--                    <a href="#" class="mr-1">-->
                  <!--                      <img :src="`${data.images[2]}`" alt />-->
                  <!--                    </a>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-card >
      <div v-html="$t('Dashboard.InfoBlock')"></div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "welcome",
  data() {
    return {
      timelineData: this.$t('Dashboard.StepsLine')
    };
  }
}
</script>

<style scoped>

</style>