import api from "@/helpers/fakebackend/api";
import store from "@/state/store"

export const tagsService = {
    getTags,
    getTag,
    createTag,
    updateTag,
    publicTag,
    unPublicTag,
    getTagSource,
    setWidgetForTag,
    saveTagHandler,
    deleteTagHandler,
    handlerTurnOn,
    handlerTurnOff,
    getTagHandlers
};

async function getTags(params = {}) {
    const request = {
        method: 'get',
        path: 'tags/tag/index',
        headers: {
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        params: params,
        properties: {}
    }
    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function getTag(data) {
    const request = {
        method: 'get',
        path: 'tags/tag/view',
        params: data
    }

    return (new api()).sendRequest(request).then(response => {
        response.data.data.data = JSON.parse(response.data.data.data)
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function getTagSource(data) {
    const request = {
        method: 'get',
        path: 'tags/tag-source/view',
        params: data
    }

    return (new api()).sendRequest(request).then(response => {
        response.data.data.data = JSON.parse(response.data.data.data)
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function createTag(data) {
    const request = {
        method: 'post',
        path: 'tags/tag-source/create',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: data
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function updateTag(data) {
    const request = {
        method: 'post',
        path: 'tags/tag-source/update',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: data
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function publicTag(tag_id) {
    const request = {
        method: 'post',
        path: 'tags/tag/public-tag?tag_id=' + tag_id,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: {}
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                if (response.data.data.errors) {
                    response.data.data.errors.forEach((error) => {
                        store.dispatch('notification/error',
                            this.$t('Tags.Errors.PublicationTagCodes.' + error.code) + error.msg
                        );
                    })
                }
                reject(response.data.data)
            }
        });
    })
}

function unPublicTag(tag_id) {
    const request = {
        method: 'post',
        path: 'tags/tag/un-public-tag?tag_id=' + tag_id,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: {}
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function setWidgetForTag(tag_id, tag_source_id) {
    const request = {
        method: 'post',
        path: 'tags/tag/set-widget-for-tag',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: { tag_id, tag_source_id }
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}

function saveTagHandler(handlerController, data) {
    const request = {
        method: 'post',
        path: 'dataHandler/'+ handlerController +'/save-handler',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: data
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}
function deleteTagHandler(data) {
    const request = {
        method: 'post',
        path: 'dataHandler/handlers/delete-handler',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: data
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}
function handlerTurnOn(data) {
    const request = {
        method: 'post',
        path: 'dataHandler/handlers/handler-turn-on',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: data
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}
function handlerTurnOff(data) {
    const request = {
        method: 'post',
        path: 'dataHandler/handlers/handler-turn-off',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        properties: data
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}
function getTagHandlers(data) {
    const request = {
        method: 'get',
        path: 'dataHandler/handlers/get-tag-handlers',
        params: data
    }

    return (new api()).sendRequest(request).then(response => {
        return new Promise((resolve, reject) => {
            if (response.data.status === 'success') {
                resolve(response.data.data)
            }
            if (response.data.status === 'error') {
                reject(response.data.data)
            }
        });
    })
}