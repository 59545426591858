<template>
  <div class="card">
    <div class="card-body">
      <div class="float-right d-none d-md-inline-block">
        <b-row>
          <b-col cols="auto" class="pr-0">
            <div class="btn-group">
              <b-button size="sm" variant="light" :pressed="group==='day'" @click="day()">
                {{$t('Dashboard.Dashboard.Daily')}}
              </b-button>
              <b-button size="sm" variant="light" :pressed="group==='month'" @click="monthly()" >
                {{$t('Dashboard.Dashboard.Monthly')}}
              </b-button>
            </div>
          </b-col>
          <b-col cols="auto">
            <DatePicker
                class="sm pt-0"
                v-model="date"
                value-type="timestamp"
                :format = "datepicker.format"
                :type = "datepicker.type"
                placeholder="Select date"
            ></DatePicker>
          </b-col>
        </b-row>



      </div>
      <h4 class="card-title mb-4">{{$t('Dashboard.Dashboard.ViewsOnTheSite')}}</h4>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
            ref = "chart"
            class="apex-charts"
            height="280"
            dir="ltr"
            :series="series"
            :options="chartOptions"
        ></apexchart>
      </div>
    </div>

    <div class="card-body border-top text-center">
      <div class="row">
        <div class="col-sm-4">
          <div class="d-inline-flex">
            <h5 class="mr-2">{{ viewsInCurrentMonth }}</h5>
            <h5 class="mb-0 text-muted font-size-16 line-height-14">/ ---</h5>
<!--            <div class="text-success">-->
<!--              <i class="mdi mdi-menu-up font-size-14"></i>2.2 %-->
<!--            </div>-->
          </div>
          <p class="text-muted text-truncate mb-0">{{ $t('Dashboard.Dashboard.Tariff') }}</p>
        </div>

        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i>
              {{ $t('Dashboard.Dashboard.ThisMonth') }}:
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0 mr-2">{{ viewsInCurrentMonth }}</h5>
<!--              <div class="text-success">-->
<!--                <i class="mdi mdi-menu-up font-size-14"></i>2.1 %-->
<!--              </div>-->
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i> {{ $t('Dashboard.Dashboard.PreviousMonth') }}:
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0">{{countInPrevMonth}}</h5>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { statService } from "@/helpers/fakebackend/stats.service"

/**
 * Revenue Analytics component
 */
export default {
  components: { DatePicker },
  data() {
    return {
      datepicker: {
        format: "YYYY-MM",
        type: "month"
      },
      date: Date.now(), // Math.floor(Date.now()/1000),
      series: [
        {
          name: "Views",
          type: "column",
          data: []
        },
        {
          name: "Previous period",
          type: "line",
          data: []
        }
      ],
      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: [0, 3],
          curve: "smooth"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%"
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: []
      },
      templateLabels: {
        day: [],
        // day: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20",21,22,23,24,25,26,27,28,29,30,31],
        monthly: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
      },
      group: 'monthly'
    };
  },
  computed: {
    currentProject: function () {
      return this.$store.getters['projects/getCurrentProjects']
    },
    viewsInCurrentMonth: function() {
      return this.$store.getters['statistics/getViewsInCurrentMonth'].data
    },
    countInPrevMonth: function() {
      return this.$store.getters['statistics/getViewsInPrevMonth'].data
    }
  },
  methods: {
    ...statService,
    monthly: function() {
      this.group = 'month'
      this.datepicker.format = "YYYY"
      this.datepicker.type = "month"

      this.$store.dispatch('statistics/getDataForCharts', { group: this.group, date: Math.floor(this.date/1000) }).then(data => {
        if (data.data === null) {
          return
        }
        this.chartOptions.labels = this.templateLabels.monthly
        this.series[0].data = data.data
        this.updateChart()
      })
      this.getDataFromPrevPeriod()
    },
    day: function() {
      this.group = 'day'

      this.datepicker.format = "YYYY-MM"
      this.datepicker.type = "date"

      this.$store.dispatch('statistics/getDataForCharts', { group: this.group, date: Math.floor(this.date/1000) }).then(data => {
        if (data.data === null) {
          return
        }
        this.generateLabelsForDay()
        this.series[0].data = data.data
        this.updateChart()
      })
      this.getDataFromPrevPeriod()
    },
    generateLabelsForDay: function() {
      this.chartOptions.labels = []
      for (let i = 1; i <= 31; i++) {
        this.chartOptions.labels.push(String(i))
      }
    },
    updateChart: function() {
      this.$refs.chart.updateOptions(this.chartOptions, false, true)
      this.$refs.chart.updateSeries(this.series);
    },
    getDataFromPrevPeriod: function() {
      let date = new Date(this.date)
      if (this.group === 'day') {
        date  = date.setMonth(date.getMonth() - 1);
      } else if (this.group === 'month') {
        date  = date.setFullYear(date.getFullYear() - 1);
      }

      this.$store.dispatch('statistics/getDataForCharts', { group: this.group, date: Math.floor(date/1000) }).then(data => {
        if (data.data === null) {
          return
        }
        this.series[1].data = data.data
        this.updateChart()
      })
    }
  },
  mounted() {
    this.day()

    this.$store.dispatch('statistics/loadViewsInCurrentMonth', this.currentProject.id).then(
        // data => {
      // console.log(data)
    // }
    )
    this.$store.dispatch('statistics/loadViewsInCurrentMonth', this.currentProject.id)

  }
};
</script>