<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-card class="bg-primary text-white text-center">
      {{$t('Dashboard.BetaMsg')}}
    </b-card>

    <LoaderBig v-if="loading === true" />

    <WelcomePage v-if="!loading && !tags.length" />

    <Visitors v-if="!loading && project" />

    <TagStatistics v-if="!loading && tags" :tags = "tags" />

  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import WelcomePage from "./_welcome"
import {tagsService} from "@/helpers/fakebackend/tags.service";
import LoaderBig from "@/components/LoaderBig";
import TagStatistics from "./_tags_statistics"
import Visitors from "./_visitors"

export default {
  name: "index",
  components: {Layout, PageHeader, WelcomePage, LoaderBig, TagStatistics, Visitors},
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Eefim"
        },
        {
          text: "Dashboard",
          active: true
        }
      ],
      loading: true,
      project: false,
      tags:[]
    };
  },
  methods: {
    ...tagsService,
    publicationTag(tag_id, tag) {
      this.publicTag(tag_id).then(() => {
        tag.status = 1
      })
    },
    unPublicationTag(tag_id, tag) {
      this.unPublicTag(tag_id).then(() => {
        tag.status = 0
      })
    },
    loadTags(project_id) {
      this.loading = true
      this.getTags({project_id: project_id}).then(result => {
        this.tags = result
        this.loading = false
      })
    }
  },
  mounted() {
    this.$store.dispatch('projects/getCurrentProjects').then((project) => {
      if (!project) {
        this.project = false
        this.loading = false
        return
      }

      this.project = project
      this.loadTags(project.id)
    })
  }
}
</script>

<style scoped>

</style>