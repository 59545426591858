<template>
  <div>
    <h3>Tags</h3>

    <b-card
        v-for="(item, index) in tags"
        :key="index"
    >
      <b-row >
        <b-col cols="2">
          <h5>
            {{item.name}}
          </h5>

          <b-badge
              class="mt-3"
              :variant="item.status === 1 ? 'success' : 'secondary'"
          >
            {{ $t("Tags.Status." + item.status)}}
          </b-badge>
        </b-col>
        <b-col cols="4" class="border-right pr-4">

          <b-skeleton
              v-if="source[item.id] === undefined"
              width="100%"
              height="60px"
          ></b-skeleton>

          <b-row v-if="source[item.id] !== undefined" align-h="between" class="flex-nowrap">
            <b-col >
              <p>
                <small>Показов за месяц: <b class="font-size-12">{{statDataForTag[item.id].countViewsImMonth}}</b></small>
              </p>
              <div v-if="source[item.id] === false">Нет данных</div>

              <apexchart
                  v-if="source[item.id]"
                  :ref="'chartTag' + item.id"
                  class="apex-charts"
                  height="35"
                  dir="ltr"
                  :series="source[item.id].series"
                  :options="source[item.id].chartOptions"
                  type="line"
                  width="100%"
              ></apexchart>
            </b-col>
            <b-col cols="auto" >
              <p class="mt-1 mb-2 small text-muted text-truncate text-center">
                Конверсия:
              </p>

              <div class="text-center">
                <b-badge pill variant="primary" class="text-center mt-2 font-size-14">{{statDataForTag[item.id].countViewsConversion}}%</b-badge>
              </div>
            </b-col>
          </b-row>



        </b-col>
        <b-col cols="4" class="ml-4 pl-0">
          <b-skeleton
              v-if="sourceSecond[item.id] === undefined"
              width="100%"
              height="60px"
          ></b-skeleton>

          <b-row v-if="sourceSecond[item.id] !== undefined" align-h="between" class="flex-nowrap">
            <b-col >
              <p>
                <small>
                  Взаимодействий за месяц:
                  <b v-if="statDataForTag[item.id].countActionsImMonth !== undefined" class="font-size-12">{{statDataForTag[item.id].countActionsImMonth}}</b>
                </small>
              </p>

              <div v-if="sourceSecond[item.id] === false">Нет данных</div>

              <apexchart
                  v-if="sourceSecond[item.id]"
                  :ref="'chartTag' + item.id"
                  class="apex-charts"
                  height="35"
                  dir="ltr"
                  :series="sourceSecond[item.id].series"
                  :options="sourceSecond[item.id].chartOptions"
                  type="line"
                  width="100%"
              ></apexchart>
            </b-col>
            <b-col cols="auto" >
              <p class="mt-1 mb-2 small text-muted text-truncate text-center">
                Конверсия:
              </p>
              <div class="text-center">
                <b-badge pill variant="primary" class="text-center mt-2 font-size-14">{{statDataForTag[item.id].countActionsConversion}}%</b-badge>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="auto" class="ml-auto text-right d-flex align-items-center">

          <router-link
              class="btn btn-light size mr-1 btn-sm"
              :to="'/tags/' + item.id"
              v-b-tooltip.hover
              :title="$t('General.Base.Edit')"
          >
            <i class="mdi mdi-pencil"></i>
          </router-link>

<!--          <router-link-->
<!--              class="btn btn-light size mr-1 btn-sm"-->
<!--              :to="'/tags/' + item.id"-->
<!--              v-b-tooltip.hover-->
<!--              :title="$t('General.Base.Statistics')"-->
<!--          >-->
<!--            <i class="mdi mdi-chart-bar"></i>-->
<!--          </router-link>-->

          <router-link
              class="btn btn-light size mr-0 btn-sm"
              :to="'/data/' + item.id"
              v-b-tooltip.hover
              :title="$t('General.Base.Data')"
          >
            <i class="mdi mdi-database"></i>
          </router-link>
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import {statService} from "@/helpers/fakebackend/stats.service";
import {clone} from "lodash";

export default {
  name: "tags_statistics",
  props: ['tags'],
  data() {
    return {
      statDataForTag: {},
      source: {},
      sourceSecond: {},
      templateSource: {
        series: [
          {
            data: []
          }
        ],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            width: [3],
            curve: "smooth"
          },
          colors: ["#5664d2"],
          tooltip: {
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return "";
                }
              }
            },
            marker: {
              show: false
            }
          }
        }
      },
    }
  },
  computed: {
    currentProject: function () {
      return this.$store.getters['projects/getCurrentProjects']
    }
  },
  methods: {
    ...statService,
    getChartForCountView: async function(tagId) {
      this.$store.dispatch('statistics/getDataForChartsTag', {
        tag_id: tagId,
        group: 'day',
        date: Math.floor(Date.now()/1000),
        eventName: 'tagRun'
      }).then(data => {
        if (!data.data.length) {
          this.$set(this.statDataForTag[tagId], 'countViewsImMonth', 0)
          this.$set(this.statDataForTag[tagId], 'countViewsConversion', 0)
          this.$set(this.source, tagId, false)
          return
        }
        this.templateSource.series[0].data = data.data
        this.$set(this.source, tagId, clone(this.templateSource))
        this.$set(this.statDataForTag[tagId], 'countViewsImMonth', data.sum)

        this.calculateConversion(tagId, data.sum).then((percent) => {
          this.$set(this.statDataForTag[tagId], 'countViewsConversion', percent)
        })
      })
    },
    getChartForCountAction: async function(tagId) {
      this.$store.dispatch('statistics/getDataForChartsTag', {
        tag_id: tagId,
        group: 'day',
        date: Math.floor(Date.now()/1000),
        eventName: 'newCheckout'
      }).then(data => {
        if (!data.data.length) {
          this.$set(this.statDataForTag[tagId], 'countActionsImMonth', 0)
          this.$set(this.statDataForTag[tagId], 'countActionsConversion', 0)
          this.$set(this.sourceSecond, tagId, false)
          return
        }
        this.templateSource.series[0].data = data.data
        this.$set(this.sourceSecond, tagId, clone(this.templateSource))
        this.$set(this.statDataForTag[tagId], 'countActionsImMonth', data.sum)

        this.calculateConversion(tagId, data.sum).then(percent => {
          this.$set(this.statDataForTag[tagId], 'countActionsConversion', percent)
        })
      })
    },
    calculateConversion: async function(tag_id, sum) {
      return this.$store.dispatch('statistics/loadViewsInCurrentMonth', this.currentProject.id).then( data => {
        return Math.ceil(sum / data.data * 100 * 100) / 100
      })
    },
    updateChart: function(tagId) {
      this.$refs['chartTag' + tagId].updateOptions(this.source[tagId].chartOptions , false, true)
      this.$refs['chartTag' + tagId].updateSeries(this.source[tagId].series);
    },
  },
  mounted() {
    this.tags.sort((x, y) => {
      return y.status < x.status ? -1 : 0
    });

    for (let i in this.tags) {
      this.statDataForTag[this.tags[i].id] = []
      // if (this.tags[i].status === 1) {
        this.getChartForCountView(this.tags[i].id)
        this.getChartForCountAction(this.tags[i].id)
      // }
    }
  }
}
</script>

<style scoped>

</style>